
import React from "react"

import LongArrowIcon from "components/svg/longArrow"

import styles from "./styles.module.scss"

export default class ExitLink extends React.Component {

	render() {

		const { className } = this.props;

		return (

			<a className={`${styles.root}${className ? ` ${className}` : ""}`} href="https://buffy.co" title="shop buffy.co">

				shop buffy.co

				<LongArrowIcon />

			</a>

		);

	}

}
