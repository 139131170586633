
import React, { useEffect } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Link, StaticQuery, graphql } from "gatsby"
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"

import Div100vh from "react-div-100vh"
import styleNameResolver from "helper/styleNameResolver"
import HomeLink from "components/homeLink"
import ExitLink from "components/exitLink"

import { update as updateApp } from "state/app"

import styles from "./styles.module.scss"
import colorStyles from "scss/color.module.scss"

let rootRef = React.createRef();

function disableScroll(e) {

	e.preventDefault();

	return false;

}

const Nav = ({ showExtendedNav, isHidden, showNav, onClick = () => {} }) => {

	useEffect(() => {

		if(showNav) {

			if ("ontouchstart" in document.documentElement) {

				document.addEventListener("touchmove", disableScroll, { passive: false });

			} else {

				disableBodyScroll(document.body);

			}

		} else {

			document.removeEventListener("touchmove", disableScroll, { passive: false });

			enableBodyScroll(document.body);

		}

	});

	return (

		<StaticQuery

			query={graphql`

					query NavQuery {

						prismic {

							allSites {

							    edges {

							    	node {

										nav {

											nav_link { 
												
												... on PRISMIC_Category {
	                								
													_meta { uid }

								                	name

									                header_background_color
									                
								              	}


											}

										}

									}

								}
						    
						    }
						  
				  		}

					}

				`
			}

			render={

				({

					prismic: { allSites: { edges: [{ node: { nav } }] } }

				}) => (

					<React.Fragment>

						<nav ref={rootRef} className={`${styles.root}${isHidden ? ` ${styles.isHidden}` : ""}${showExtendedNav ? ` ${styles.showExtendedNav}` : ""}`}>

							<Div100vh>

								<HomeLink onClick={onClick} className={styles.homeLink} />

								{
									nav.map(

										({
											nav_link: {
												_meta: { uid },
												name,
												header_background_color

											}

										}) =>

											<Link 
												key={uid} 
												to={`/${uid}`} 
												className={`${styles.navLink} ${colorStyles[styleNameResolver(header_background_color, "background")]}`}
												onClick={onClick}
											>

												{name[0].text}

											</Link>

									)
								}

								<ExitLink />

							</Div100vh>

						</nav>

						{ showNav && <div className={styles.closeSpace} onClick={onClick} /> }

					</React.Fragment>

				)

			}

		/>

	);

};

export default connect(

	state => ({

		showExtendedNav: state.app.showExtendedNav,
		showNav: state.app.showNav

	}),

	dispatch => bindActionCreators({ updateApp }, dispatch)

)(Nav);
