
import { graphql } from "gatsby"

export const postHeader = graphql`
	
	fragment PostHeader on PRISMIC_Post_default {

		_meta { uid tags firstPublicationDate }

		visibility

		title

		subtitle
		
		date_published
		
		primary_credit
		
		secondary_credit
		
		category {
		
			... on PRISMIC_Category { 

				name
				header_background_color

			}
		
		}
		
		description
		
		image
		
		video
		
		video_duration
		
		background_color
		
		text_color
		
		layout

	}

`;

export const post = graphql`

	fragment Post on PRISMIC_Post_default {

		...PostHeader

		body {
                
			... on PRISMIC_Post_defaultBodyText {

				type
				primary {
					text
					background_color
					text_color
					text_size
				}

			}

			... on PRISMIC_Post_defaultBodyQuote {

				type
				primary {
					quote
					speaker_name
					background_color
					text_color
					type_size
					quotation_marks
					image
				}

			}

			... on PRISMIC_Post_defaultBodyListicle {

				type
				primary {
					start_index
					background_color
					text_color
					index_color
				}

				fields {
					item_title
					item_content
				}

			}

			... on PRISMIC_Post_defaultBodyInterview {

				type
				primary {
					background_color
					text_color
				}

				fields {
              	  speaker_name
              	  speaker_response
              	}

			}

			... on PRISMIC_Post_defaultBodyImage_gallery {

				type
				primary {
					background_color
					text_color
				}

				fields {
					image_credit
					image_caption
					image_size
					grid_image
				}

			}

			... on PRISMIC_Post_defaultBodyMedia_slideshow {

				type
				primary {
					title
					text
					background_color
					text_color
				}

				fields {
					image
					image_caption
				}

			}

			... on PRISMIC_Post_defaultBodyFullscreen_image {

				type
				primary {
					image
					image_credit
					image_caption
					background_color
					text_color
				}

			}

			__typename

		}

		related_articles {
               
            ... on PRISMIC_Post_defaultRelated_articlesArticle {

				primary {

					article {

						...PostHeader

					}

				}
            
            }

            __typename
                
      	}

	}

`;