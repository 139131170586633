
import React from "react"
import { connect } from "react-redux"
import { StaticQuery, graphql } from "gatsby"

import BuffyLogo from "components/svg/buffyLogo"
import NewsletterForm from "components/newsletterForm"

import styles from "./styles.module.scss"

function Footer({ showNav }) { 

	return (

		<StaticQuery

			query={graphql`

					query FooterQuery {

						prismic {

							allSites {

							    edges {

							    	node {

										column_1_footer_links {

											column_1_footer_link {

												... on PRISMIC__ExternalLink {
                    
								                    url
								                    
							                  	}

											}

											column_1_footer_link_title

										}

										column_2_footer_links {

											column_2_footer_link {

												... on PRISMIC__ExternalLink {
                    
								                    url
								                    
							                  	}

											}

											column_2_footer_link_title

										}
          
          								newsletter_footer_success_message

									}
							    
							    }
							  
					  		}

				  		}

					}

				`
			}

			render={

				({

					prismic: { allSites: { edges: [{ node: { 

						column_1_footer_links,
						column_2_footer_links,
						newsletter_footer_success_message

					} }] } }

				}) => (

					<footer className={`${styles.root}`}>

						<div>
							
							<a href="https://buffy.co" title="buffy.co">
								
								<BuffyLogo className={styles.logo} />
							
							</a>

						</div>

						{
							[
								column_1_footer_links,
								column_2_footer_links
							]
								.map((footer_links, index) => (

										<nav className={styles.nav} key={index}>

											{
												footer_links.map(

													data => {

														const [{ text }] = data[`column_${index + 1}_footer_link_title`];
														const { url } = data[`column_${index + 1}_footer_link`];

														return (

															<a key={url} href={url} target="_blank" rel="noopener noreferrer">

																{text}

															</a>

														);

													}

												)
											}

										</nav>
									
									)

								)

						}

						<div>

							<h3>
								Newsletter
							</h3>

							<NewsletterForm successMessage={(newsletter_footer_success_message || [{}])[0].text} />

						</div>

					</footer>

				)

			}

		/>

	)

};

export default connect(

	state => ({

		showNav: state.app.showNav

	})

)(Footer);
