
import React from "react"

export default class Ellipsis extends React.Component {

	render() {

		return (

			<svg {...this.props} viewBox="0 0 30 6">

				<path d="M3,0A3,3,0,1,1,0,3,3,3,0,0,1,3,0ZM15,0a3,3,0,1,1-3,3A3,3,0,0,1,15,0ZM27,0a3,3,0,1,1-3,3A3,3,0,0,1,27,0Z" />

			</svg>

		);

	}

}