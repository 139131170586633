
import React from "react"
import axios from "axios"

import styles from "./styles.module.scss"

export default class NewsletterForm extends React.Component {

	constructor() {

		super();

		this.state = {

			success: false

		};

		this.formRef = React.createRef();

	}

	encode(data) { 

		return Object.keys(data).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`).join("&");
  	
  	}

	submit(e) {

		e.preventDefault();

		const data = {};

		const {
			onSuccess = () => {}
		
		} = this.props;

		Array.from(e.target)

			.forEach(({ nodeName, value, name }) => {

				if(nodeName === "INPUT")

					data[name] = value;

			});

		axios.post("/", this.encode(data), { "Content-Type": "application/x-www-form-urlencoded" })

			.then(() => {

				this.formRef.current.reset();

				onSuccess();

				this.setState({ success: true });

				setTimeout(() => {

					this.setState({ success: false });

				}, 3000);

				if(window.dataLayer)

					window.dataLayer.push({ 

						"event": "subscribed-to-newsletter", 
						"email": data.email,
						"url": window.location.pathname

					});

			})

				.catch(console.log);

	}

	render() {

		const {
			className,
			successMessage = "Enter email address"
		
		} = this.props;

		const {
			success
		
		} = this.state;

		return (

			<form 
				ref={this.formRef}
				name="newsletter" 
				method="POST" 
				data-netlify="true" 
				className={`${styles.root}${className ? ` ${className}` : ""}`}
				onSubmit={this.submit.bind(this)}
			>

				<input type="hidden" name="form-name" value="newsletter" />

				<input 
					type="email" 
					name="email" 
					placeholder={success ? successMessage : "Enter email address"}
					disabled={success}
					required 
				/>

				<button type="submit" />

			</form>

		);

	}

}