
import React from "react"
import { connect } from "react-redux"

import NewsletterModal from "components/newsletterModal"

import styles from "./styles.module.scss"

class Main extends React.Component {

	render() {

		const {
			children,
			showNewsletter
		
		} = this.props;

		return (

			<React.Fragment>

				<main className={`${styles.root}`}>

					{children}

				</main>

				{ showNewsletter && <NewsletterModal /> }
				
			</React.Fragment>

		);

	}

}

export default connect(

	state => ({

		showNewsletter: state.app.showNewsletter

	})

)(Main);