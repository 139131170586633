
import React from "react"

import "lazysizes"
import "lazysizes/plugins/parent-fit/ls.parent-fit"

export default class ResponsiveImage extends React.Component {

	render() {

		const {
			src,
			className
		
		} = this.props;

		const fetchUrl = (src, width) => `https://res.cloudinary.com/softspace-buffy/image/fetch/w_${width},f_auto,c_limit/${src}`; 

		const sizes = [800, 1200, 1600, 2000, 2400, 2750, 3000];

		return (

			<img 
				{...this.props} 
				src={fetchUrl(src, 800)}
				data-sizes="auto" 
				data-srcset={

					sizes.map(width => `${fetchUrl(src, width)} ${width}w`).join(",")

				}

				data-expand={800}

				className={`${className ? `${className} ` : ""}lazyload`}

				alt=""

			/>

		);

	}

}