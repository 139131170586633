
import React from "react"

export default class LongArrow extends React.Component {

	render() {

		return (

			<svg {...this.props} viewBox="0 0 200 200">

				<path d="M199,100H0m128.64,70.36L199,100,128.64,29.64" vectorEffect="non-scaling-stroke" />

			</svg>

		);

	}

}