
const FEED_ADD = "FEED_ADD";

const initialState = {

	feeds: {}

};

export default (state = initialState, action) => {

	const {
		type,
		data
	
	} = action;

	switch(type) {

		case FEED_ADD:

			return {

				...state,

				feeds: {

					...(() => {

						const feed = state.feeds[data.id] || {};

						feed.items = Array.from(new Set([...(feed.items || []), ...data.items]));

						delete data.items;

						state.feeds[data.id] = {...feed, ...data};

						return {...state.feeds};

					})()

				}

			};

		default:

			return state;

	}

}
