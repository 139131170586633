
import React from "react"

export default class Close extends React.Component {

	render() {

		return (
			
			<svg {...this.props} viewBox="0 0 14.14 14.14">

				<path d="M7.07,5.66L12.73,0l1.41,1.41L8.49,7.07l5.66,5.66-1.41,1.41L7.07,8.49,1.41,14.14,0,12.73,5.66,7.07,0,1.41,1.41,0Z"/>

			</svg>

		);

	}

}