
import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import ExitLink from "components/exitLink"
import HomeLink from "components/homeLink"

import { update as updateApp } from "state/app"

import styles from "./styles.module.scss"

class Header extends React.Component {

	constructor() {

		super();

		this.rootRef = React.createRef();

	}

	componentDidMount() {

		const { current } = this.rootRef;

		window.addEventListener("scroll", () => {

			if(this.props.navIsHidden)

				this.props.updateApp({

					showExtendedNav: window.scrollY > current.getBoundingClientRect().height

				});

		});

	}

	componentWillReceiveProps({ navIsHidden }) {

		if(navIsHidden !== this.props.navIsHidden && navIsHidden)

			this.props.updateApp({

				showExtendedNav: window.scrollY > this.rootRef.current.getBoundingClientRect().height

			});

	}

	render() {

		const {
			headerClass

		} = this.props;

		return (

			<div ref={this.rootRef}>

				<header className={`${styles.root}${ headerClass ? ` ${headerClass}` : "" }`}>

					<div />

					<HomeLink className={styles.logo} />

					<ExitLink className={styles.exit} />

				</header>

			</div>

		);

	}

}

export default connect(

	state => ({

		headerClass: state.app.headerClass,
		navIsHidden: state.app.navIsHidden

	}),

	dispatch => bindActionCreators({ updateApp }, dispatch)

)(Header);
