module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"soft-space","accessToken":"MC5YTlJTbWhBQUFCVktCUHpa.NO-_ve-_ve-_ve-_ve-_vWvvv73vv702XTxF77-977-977-977-9enDvv71jS--_ve-_vVMtAe-_ve-_vQ8w77-9","previews":true,"path":"/preview"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-109656188-1"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-T73R87Z"},
    },{
      plugin: require('/opt/build/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
