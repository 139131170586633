
import { navigate } from "gatsby"

const hook = e => {

	const {
		hookRouter

	} = e.target.dataset;

	if(hookRouter) {

		e.preventDefault();

		navigate(e.target.getAttribute("href"));

	}

}

export default function () {

	Array.from(document.getElementsByTagName("a") || [])

		.forEach(

			node => {

				node.removeEventListener("click", hook);

				node.addEventListener("click", hook);

			}

		);

}