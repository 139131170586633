
import React, { useEffect } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { StaticQuery, graphql } from "gatsby"

import NewsletterForm from "components/newsletterForm"
import Close from "components/svg/close"
import parseHtml from "helper/parseHtml"
import styleNameResolver from "helper/styleNameResolver"
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"

import { update as updateApp } from "state/app"

import styles from "./styles.module.scss"
import colorStyles from "scss/color.module.scss"

let rootRef = React.createRef();

function disableScroll(e) {

	e.preventDefault(); 

	return false;

}

function NewsletterModal({ updateApp }) {

	const [success, setSuccess] = React.useState(false);

	useEffect(() => {

		if ("ontouchstart" in document.documentElement) {

			document.addEventListener("touchmove", disableScroll, { passive: false });

		} else {

			disableBodyScroll(document.body);

		}

		return () => { 

			enableBodyScroll(document.body);

			document.removeEventListener("touchmove", disableScroll, { passive: false });

		};

	});

	return (

		<StaticQuery

			query={graphql`

					query NewsletterQuery {

						prismic {
			
							allSites {

								edges {

									node {

										newsletter_title

										newsletter_image

										newsletter_text_color
          
          								newsletter_background_color

          								newsletter_modal_success_message

									}

								}

							}

						}

					}

				`
			}

			render={

				({

					prismic: { allSites: { edges: [{ node: { 

						newsletter_title, 
						newsletter_image,
						newsletter_background_color,
						newsletter_text_color,
						newsletter_modal_success_message

					} }] } }

				}) => (

					<div 

						ref={rootRef} 
						className={`${styles.root} ${colorStyles[styleNameResolver(newsletter_text_color, "text")]}${success ? ` ${styles.success}` : ""}`}

						onClick={({ target, currentTarget}) => {

							if(target.isSameNode(currentTarget))

							 	updateApp({ showNewsletter: false }); 

						}}

					>

						<div className={`${styles.content} ${colorStyles[styleNameResolver(newsletter_background_color, "background")]}`}>

							<div>

								<h2 className={styles.title}>

									Newsletter

								</h2>

								{
								
									newsletter_title && <div className={styles.subtitle}>{parseHtml(newsletter_title)}</div>

								}

								<NewsletterForm 
									
									className={styles.form}

									onSuccess={() => {

										setSuccess(true);

									}}

								/>

							</div>

							<div>

								{

									newsletter_image && <img src={newsletter_image.url} alt="" />
								
								}
							
							</div>

							<div className={styles.successMessage}>

								{parseHtml(newsletter_modal_success_message)}

							</div>

							<div className={styles.close} onClick={() => { updateApp({ showNewsletter: false }); }}>

								<Close />

							</div>

						</div>

					</div>

				)

			}

		/>

	)

};

export default connect(

	null,

	dispatch => bindActionCreators({ updateApp }, dispatch)

)(NewsletterModal);
