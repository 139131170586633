// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js": () => import("/opt/build/repo/node_modules/gatsby-source-prismic-graphql/components/PreviewPage.js" /* webpackChunkName: "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js" */),
  "component---src-templates-post-index-js": () => import("/opt/build/repo/src/templates/post/index.js" /* webpackChunkName: "component---src-templates-post-index-js" */),
  "component---src-templates-contributor-index-js": () => import("/opt/build/repo/src/templates/contributor/index.js" /* webpackChunkName: "component---src-templates-contributor-index-js" */),
  "component---src-templates-tag-index-js": () => import("/opt/build/repo/src/templates/tag/index.js" /* webpackChunkName: "component---src-templates-tag-index-js" */),
  "component---src-templates-category-index-js": () => import("/opt/build/repo/src/templates/category/index.js" /* webpackChunkName: "component---src-templates-category-index-js" */),
  "component---src-templates-home-index-js": () => import("/opt/build/repo/src/templates/home/index.js" /* webpackChunkName: "component---src-templates-home-index-js" */),
  "component---src-pages-404-index-js": () => import("/opt/build/repo/src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */)
}

