
import React from "react"
import Helmet from "react-helmet"
import { StaticQuery, graphql } from "gatsby"

export default function MetaData({ showNav }) { 

	return (

		<StaticQuery

			query={graphql`

					query MetaDataQuery {

						prismic {

							allSites {

							    edges {

							    	node {

										title

										description

										keywords

										open_graph_image

									}
							    
							    }
							  
					  		}

				  		}

					}

				`
			}

			render={

				({

					prismic: { allSites: { edges: [{ node: { 

						title,
						description,
						keywords,
						open_graph_image

					} }] } }

				}) => (

					<Helmet>

						{
							title && <title>{title[0].text}</title>
						}

						{
							description && <meta name="description" content={description[0].text} />
						}

						{
							keywords && <meta name="keywords" content={keywords[0].text} />
						}

						{
							open_graph_image && <meta property="og:image" content={`https://res.cloudinary.com/softspace-buffy/image/fetch/w_800,f_auto,c_limit/${open_graph_image.url}`} />
						}

						<link rel="apple-touch-icon" sizes="57x57" href="/images/apple-icon-57x57.png" />
						<link rel="apple-touch-icon" sizes="60x60" href="/images/apple-icon-60x60.png" />
						<link rel="apple-touch-icon" sizes="72x72" href="/images/apple-icon-72x72.png" />
						<link rel="apple-touch-icon" sizes="76x76" href="/images/apple-icon-76x76.png" />
						<link rel="apple-touch-icon" sizes="114x114" href="/images/apple-icon-114x114.png" />
						<link rel="apple-touch-icon" sizes="120x120" href="/images/apple-icon-120x120.png" />
						<link rel="apple-touch-icon" sizes="144x144" href="/images/apple-icon-144x144.png" />
						<link rel="apple-touch-icon" sizes="152x152" href="/images/apple-icon-152x152.png" />
						<link rel="apple-touch-icon" sizes="180x180" href="/images/apple-icon-180x180.png" />
						<link rel="icon" type="image/png" sizes="192x192"  href="/images/android-icon-192x192.png" />
						<link rel="icon" type="image/png" sizes="32x32" href="/images/favicon-32x32.png" />
						<link rel="icon" type="image/png" sizes="96x96" href="/images/favicon-96x96.png" />
						<link rel="icon" type="image/png" sizes="16x16" href="/images/favicon-16x16.png" />
						<link rel="manifest" href="/images/manifest.json" />
						<meta name="msapplication-TileColor" content="#ffffff" />
						<meta name="msapplication-TileImage" content="/images/ms-icon-144x144.png" />
						<meta name="theme-color" content="#ffffff" />

					</Helmet>

				)

			}

		/>

	)

};
