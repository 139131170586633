
import React from "react"

import MetaData from "components/metaData"
import Layout from "components/layout"
import linkResolver from "helper/linkResolver"
import hookRouter from "helper/hookRouter"

import { registerLinkResolver} from "gatsby-source-prismic-graphql"

import "graphQLFragments"

import "scss/global.scss"

export { default as wrapRootElement } from "state/reduxWrapper"

registerLinkResolver(linkResolver);

export function wrapPageElement({ element }) {

	if(element.props.custom404)

		return element;

	return (

		<React.Fragment>

			<MetaData />

			<Layout>

				{element}

			</Layout>

  			<script dangerouslySetInnerHTML={{

  				__html: `

  					window.prismic = {

						endpoint: 'https://soft-space.cdn.prismic.io/api/v2'

					};

  				`
  			}} />

			<script src="https://static.cdn.prismic.io/prismic.min.js" />

  		</React.Fragment>
	
	);

}

let timeElapsedId;

export function onInitialClientRender() {

	timeElapsedId = 

		setTimeout(() => {

			if(window.dataLayer)

				window.dataLayer.push({ "event": "15-seconds-elapsed", "url": window.location.pathname });

		}, 15000);

}

export function onRouteUpdate({ location }) {

	hookRouter();

	clearTimeout(timeElapsedId);

	timeElapsedId = 

		setTimeout(() => {

			if(window.dataLayer)

				window.dataLayer.push({ "event": "15-seconds-elapsed", "url": location.pathname });

		}, 15000);

}