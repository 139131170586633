
export default doc => {

	switch(doc.type) {

		case "post_default":

			return `/post/${doc.uid}`;

		case "contributor":

			return `/contributor/${doc.uid}`;

		case "category":

			return `/${doc.uid}`;

		default:

	}

	return "/";

}