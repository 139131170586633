
import React from "react"
import ReactDOMServer from "react-dom/server"
import ReactHtmlParser from "react-html-parser"
import PrismicDOM from "prismic-dom"
import ResponsiveImage from "components/responsiveImage"

import linkResolver from "helper/linkResolver"

const htmlResolver = (type, element, content, children) => {

	switch(type) {

		case PrismicDOM.RichText.Elements.hyperlink:

			if(element.data.link_type === "Document")

				return `<a href="${linkResolver(element.data)}" data-hook-router="true">${content}</a>`;

			break;

		case PrismicDOM.RichText.Elements.image:

				return ReactDOMServer.renderToString(<ResponsiveImage src={element.url} />);

		default:

	}

}

export default content => content ? ReactHtmlParser(PrismicDOM.RichText.asHtml(content, linkResolver, htmlResolver)) : null;