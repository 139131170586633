
import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import Header from "components/header"
import Footer from "components/footer"
import Main from "components/main"
import Nav from "components/nav"
import hookRouter from "helper/hookRouter"

import EllipsisIcon from "components/svg/ellipsis"
import CloseIcon from "components/svg/close"

import { update as updateApp } from "state/app"

import styles from "./styles.module.scss"
import layoutStyles from "scss/layout.module.scss"

class Layout extends React.Component {

	componentDidMount() {

		hookRouter();

	}

	render() {

		const {
			showNav,
			navIsHidden,
			updateApp,
			children
		
		} = this.props;

		return (

			<React.Fragment>

				<div className={styles.empty} />{/* bug fix - ssr not rendering css modules */}

				<div className={styles.navToggle} onClick={() => {

					if(!showNav)

						updateApp({ navIsHidden: false });

					updateApp({ showNav: !showNav });

				}}>

					{
						showNav ?

							<CloseIcon className={styles.close} />

							:

							<EllipsisIcon />
					}

				</div>

				<div 
					className={`${layoutStyles.willShift}${showNav ? ` ${layoutStyles.shift}` : ""}`}

					onTransitionEnd={() => { 

						if(showNav === false)

							updateApp({ navIsHidden: true });

					}}

				>

					<Nav onClick={() => updateApp({ showNav: false })} isHidden={navIsHidden} />

					<div>

						<Header />

						<Main>

							{children}

						</Main>

						<Footer />

					</div>

				</div>

			</React.Fragment>

		); 

	}

}

export default connect(

	state => ({

		showNav: state.app.showNav,
		navIsHidden: state.app.navIsHidden

	}),

	dispatch => bindActionCreators({ updateApp }, dispatch)

)(Layout);
