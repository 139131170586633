
const APP_UPDATE = "APP_UPDATE";

const initialState = {

	showNav: false,
	showNewsletter: false,
	navIsHidden: true,
	loadingPosts: false,
	scrollToPostContent: false,
	postsViewed: []


};

export function update(data) {

	return dispatch => {

		dispatch({

			type: APP_UPDATE,
			data

		});

	};

}

export default (state = initialState, action = {}) => {

	const {
		type,
		data
	
	} = action;

	switch(type) {

		case APP_UPDATE:

			return {

				...state,
				...data

			};

		default:

			return state;

	}

};