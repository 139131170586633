
import React from "react"
import { Link } from "gatsby"

import styles from "./styles.module.scss"

export default class HomeLink extends React.Component {

	render() {

		const { 
			className,
			onClick

		} = this.props;

		return (

			<Link onClick={onClick} className={`${styles.root}${className ? ` ${className}` : ""}`} to="/" title="soft-space">

				<h2>

					Soft—Space

				</h2>

			</Link>

		);

	}

}
