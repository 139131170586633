
import { postHeader } from "graphQLFragments"
import scrollIntoView from "scroll-into-view"

const POST_ADD = "POST_ADD";
const FEED_ADD = "FEED_ADD";

const initialState = {

	posts: {}

};

export function add({ feedId, prismic, pageInfo, query, variables = {} }) {

	return dispatch => {

		dispatch({

			type: "APP_UPDATE",

			data: { loadingPosts: true }

		});

		return prismic.load({

			variables: {

				first: 2,
				after: pageInfo.endCursor,
				...variables

			},

			fragments: [ postHeader ],

			query

		})

			.then(({ data: { posts: { pageInfo, edges } } }) => {

				dispatch({

					type: POST_ADD,

					data: edges

				});

				dispatch({

					type: FEED_ADD,

					data: {

						id: feedId,

						pageInfo,

						items: edges.map(({ node: { _meta: { uid } } }) => uid)

					}

				});

				dispatch({

					type: "APP_UPDATE",

					data: { loadingPosts: false }

				});

				const firstElement = document.getElementById(edges[0].node._meta.uid);

				if(firstElement)

					scrollIntoView(firstElement);

			})

				.catch(error => {

					console.log(error);

					dispatch({

						type: "APP_UPDATE",

						data: { loadingPosts: false }

					});

				});

	}

}


export default (state = initialState, action) => {

	const {
		type,
		data
	
	} = action;

	switch(type) {

		case POST_ADD:

			return {

				...state,

				posts: {

					...(() => {

						const posts = {...state.posts};

						data.forEach(post => posts[post.node._meta.uid] = post);

						return posts;

					})()

				}

			};

		default:

			return state;

	}

}
